import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  @ViewChild('sidenav') sidenav!: MatSidenav;

  constructor(private router: Router) {}

  closeSidenav() {
    this.sidenav.close();
  }

  navigateTo(section: string) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      this.sidenav.close();
    }
  }


  openBusinessSite(): void {
    window.open('https://empresa.brazucaindica.com.br/', '_blank'); // Substitua pelo URL real para empresas e negócios
  }

  openUserSite(): void {
    window.open('https://www.brazucaindica.com.br/', '_blank'); // Substitua pelo URL real para usuários do app
  }

  onSubmit(form: any) {
    console.log('Form data: ', form);
    // Aqui você pode adicionar a lógica para enviar o formulário
  }
}
