<mat-sidenav-container>
  <mat-sidenav-content>
    <!-- Hero Section com apresentação do app e tela principal -->
    <section id="inicio" class="hero-section d-flex align-items-center justify-content-center">
      <div class="container text-center text-light">
        <h1 class="hero-title">Bem-vindo ao Brazuca Indica</h1>
        <p class="hero-description">
          O guia definitivo que promove a cultura brasileira pelo mundo.
        </p>
        
        <!-- Tela principal do aplicativo -->
        <div class="app-screenshot mt-4">
          <img src="/telainicial.png" alt="Tela Principal do Brazuca Indica" class="screenshot img-fluid shadow-lg">
        </div>
        
        <div class="download-buttons mt-4">
          <a href="https://play.google.com/store/apps/details?id=brazucaindica.pt" target="_blank" class="btn btn-download mx-2">
            <i class="fab fa-google-play"></i> Google Play
          </a>
          <a href="https://apps.apple.com/pt/app/brazuca-indica/id6503343915?l=pt-br"  target="_blank" class="btn btn-download mx-2">
            <i class="fab fa-apple"></i> App Store
          </a>
        </div>
      </div>
    </section>

    <!-- Seção de Redirecionamento com animação -->
    <section id="redirect" class="redirect-section py-5">
      <div class="container text-center">
        <h2>Seja parte do Brazuca Indica</h2>
        <p>Escolha uma das opções abaixo:</p>
        <div class="row justify-content-center mt-4">
          <div class="col-md-4 mb-3">
            <button class="btn btn-action w-100" (click)="openBusinessSite()">Empresas e Negócios</button>
          </div>
          <div class="col-md-4 mb-3">
            <button class="btn btn-action w-100" (click)="openUserSite()">Usuário do Aplicativo</button>
          </div>
        </div>
      </div>
    </section>

    <footer class="footer bg-primary text-white py-3">
      <div class="container text-center">
        <p>&copy; 2024 Brazuca Indica. Todos os direitos reservados.</p>
        <div>
          <a routerLink="/terms" class="text-white mx-2">Termos e Condições</a> |
          <a routerLink="/policy" class="text-white mx-2">Política de Privacidade</a>
        </div>
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>