<!-- src/app/terms-conditions/terms-conditions.component.html -->
<mat-toolbar color="primary" class="mat-elevation-z4">
    <span class="app-title">Termos e Condições</span>
</mat-toolbar>

<section class="section bg-white">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="card-title">Termos e Condições</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="terms-conditions">
                <p>Estes termos e condições aplicam-se ao aplicativo Brazuca Indica (doravante referido como
                    "Aplicativo") para dispositivos móveis criado por João Henrique Silva (doravante referido como
                    "Prestador de Serviço") como um serviço gratuito.</p>

                <p>Ao baixar ou utilizar o Aplicativo, você está automaticamente concordando com os seguintes termos. É
                    fortemente recomendado que você leia e compreenda esses termos antes de usar o Aplicativo. A cópia
                    não autorizada, modificação do Aplicativo, qualquer parte do Aplicativo, ou nossas marcas
                    registradas são estritamente proibidas. Quaisquer tentativas de extrair o código-fonte do
                    Aplicativo, traduzir o Aplicativo para outros idiomas ou criar versões derivadas não são permitidas.
                    Todas as marcas registradas, direitos autorais, direitos sobre bancos de dados e outros direitos de
                    propriedade intelectual relacionados ao Aplicativo permanecem propriedade do Prestador de Serviço.
                </p>

                <p>O Prestador de Serviço está dedicado a garantir que o Aplicativo seja o mais útil e eficiente
                    possível. Assim, ele se reserva o direito de modificar o Aplicativo ou cobrar por seus serviços a
                    qualquer momento e por qualquer motivo. O Prestador de Serviço garante que quaisquer cobranças pelo
                    Aplicativo ou seus serviços serão claramente comunicadas a você.</p>

                <p>O Aplicativo armazena e processa dados pessoais que você forneceu ao Prestador de Serviço para
                    fornecer o Serviço. É sua responsabilidade manter a segurança do seu telefone e o acesso ao
                    Aplicativo. O Prestador de Serviço recomenda fortemente que você não faça jailbreak ou root no seu
                    telefone, o que envolve remover restrições e limitações de software impostas pelo sistema
                    operacional oficial do seu dispositivo. Essas ações podem expor seu telefone a malware, vírus,
                    programas maliciosos, comprometer os recursos de segurança do seu telefone e resultar no não
                    funcionamento correto ou total do Aplicativo.</p>

                <h2>Serviços de Terceiros</h2>
                <p>Por favor, note que o Aplicativo utiliza serviços de terceiros que possuem seus próprios Termos e
                    Condições. Abaixo estão os links para os Termos e Condições dos provedores de serviços de terceiros
                    usados pelo Aplicativo:</p>
                <ul>
                    <li><a href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a></li>
                    <li><a href="https://firebase.google.com/support/privacy" target="_blank">Google Analytics for
                            Firebase</a></li>
                </ul>

                <p>Esteja ciente de que o Prestador de Serviço não assume responsabilidade por certos aspectos. Algumas
                    funções do Aplicativo requerem uma conexão ativa com a internet, que pode ser Wi-Fi ou fornecida
                    pelo seu provedor de rede móvel. O Prestador de Serviço não pode ser responsabilizado se o
                    Aplicativo não funcionar em plena capacidade devido à falta de acesso ao Wi-Fi ou se você tiver
                    esgotado sua franquia de dados.</p>

                <p>Se você estiver usando o aplicativo fora de uma área com Wi-Fi, por favor, esteja ciente de que os
                    termos do acordo do seu provedor de rede móvel ainda se aplicam. Consequentemente, você pode
                    incorrer em cobranças do seu provedor móvel pelo uso de dados durante a conexão com o aplicativo, ou
                    outras cobranças de terceiros. Ao usar o aplicativo, você aceita a responsabilidade por tais
                    cobranças, incluindo cobranças de dados em roaming se você usar o aplicativo fora do seu território
                    de origem (ou seja, região ou país) sem desativar o roaming de dados. Se você não for o pagador da
                    conta pelo dispositivo no qual está usando o aplicativo, presume-se que você obteve permissão do
                    pagador da conta.</p>

                <p>Da mesma forma, o Prestador de Serviço não pode sempre assumir responsabilidade pelo seu uso do
                    Aplicativo. Por exemplo, é sua responsabilidade garantir que seu dispositivo permaneça carregado. Se
                    seu dispositivo ficar sem bateria e você não conseguir acessar o Serviço, o Prestador de Serviço não
                    pode ser responsabilizado.</p>

                <p>Em termos de responsabilidade do Prestador de Serviço pelo seu uso do Aplicativo, é importante notar
                    que, embora eles se esforcem para garantir que ele esteja atualizado e preciso em todos os momentos,
                    eles dependem de terceiros para fornecer informações a eles para que possam disponibilizá-las a
                    você. O Prestador de Serviço não aceita responsabilidade por qualquer perda, direta ou indireta, que
                    você possa experimentar como resultado de confiar totalmente nesta funcionalidade do Aplicativo.</p>

                <p>O Prestador de Serviço pode desejar atualizar o Aplicativo em algum momento. O Aplicativo está
                    atualmente disponível conforme os requisitos do sistema operacional (e para quaisquer sistemas
                    adicionais que decidam estender a disponibilidade do Aplicativo) podem mudar, e você precisará
                    baixar as atualizações se quiser continuar usando o Aplicativo. O Prestador de Serviço não garante
                    que sempre atualizará o Aplicativo para que seja relevante para você e/ou compatível com a versão
                    específica do sistema operacional instalada no seu dispositivo. No entanto, você concorda em sempre
                    aceitar atualizações para o Aplicativo quando oferecidas a você. O Prestador de Serviço pode também
                    desejar cessar a prestação do Aplicativo e pode rescindir seu uso a qualquer momento sem fornecer
                    aviso de rescisão a você. A menos que informem o contrário, após qualquer rescisão, (a) os direitos
                    e licenças concedidos a você nestes termos terminarão; (b) você deve parar de usar o Aplicativo e,
                    se necessário, excluí-lo do seu dispositivo.</p>

                <h2>Alterações a Estes Termos e Condições</h2>
                <p>O Prestador de Serviço pode atualizar periodicamente seus Termos e Condições. Portanto, você é
                    aconselhado a revisar esta página regularmente para quaisquer alterações. O Prestador de Serviço
                    notificará você de quaisquer mudanças postando os novos Termos e Condições nesta página.</p>

                <p>Estes termos e condições são válidos a partir de 29 de maio de 2024.</p>

                <h2>Contate-nos</h2>
                <p>Se você tiver quaisquer perguntas ou sugestões sobre os Termos e Condições, por favor, não hesite em
                    contactar o Prestador de Serviço em contato&#64;brazucaindica.pt.</p>

            </div>
        </mat-card-content>
    </mat-card>
</section>
