<!-- src/app/policy/policy.component.html -->
<mat-toolbar color="primary" class="mat-elevation-z4">
    <span class="app-title">Política de Privacidade</span>
</mat-toolbar>

<section class="section bg-white">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="card-title">Política de Privacidade</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="privacy-policy">
                <strong>Política de Privacidade</strong>
                <p>Esta política de privacidade se aplica ao aplicativo Brazuca Indica (doravante referido como "Aplicativo") para dispositivos móveis que foi criado por João Henrique Silva (doravante referido como "Prestador de Serviço") como um serviço gratuito. Este serviço é fornecido para uso "TAL COMO ESTÁ".</p>

                <br><strong>Coleta e Uso de Informações</strong>
                <p>O Aplicativo coleta informações quando você o baixa e utiliza. Essas informações podem incluir:</p>
                <ul>
                    <li>O endereço IP do seu dispositivo</li>
                    <li>As páginas do Aplicativo que você visita, a hora e a data da sua visita, o tempo gasto nessas páginas</li>
                    <li>O tempo gasto no Aplicativo</li>
                    <li>O sistema operacional que você usa no seu dispositivo móvel</li>
                </ul>

                <p>O Aplicativo coleta a localização do seu dispositivo, o que ajuda o Prestador de Serviço a determinar sua localização geográfica aproximada e usá-la das seguintes maneiras:</p>
                <ul>
                    <li>Serviços de Geolocalização: O Prestador de Serviço utiliza dados de localização para fornecer recursos como conteúdo personalizado, recomendações relevantes e serviços baseados na localização.</li>
                    <li>Análises e Melhorias: Dados de localização agregados e anonimizados ajudam o Prestador de Serviço a analisar o comportamento dos usuários, identificar tendências e melhorar o desempenho e a funcionalidade geral do Aplicativo.</li>
                    <li>Serviços de Terceiros: Periodicamente, o Prestador de Serviço pode transmitir dados de localização anonimizados para serviços externos. Esses serviços os ajudam a melhorar o Aplicativo e otimizar suas ofertas.</li>
                </ul>

                <p>O Prestador de Serviço pode usar as informações que você forneceu para contatá-lo de tempos em tempos para fornecer informações importantes, avisos necessários e promoções de marketing.</p>

                <p>Para uma melhor experiência, enquanto usa o Aplicativo, o Prestador de Serviço pode exigir que você forneça certas informações pessoalmente identificáveis. As informações que o Prestador de Serviço solicitar serão retidas por eles e usadas conforme descrito nesta política de privacidade.</p>

                <br><strong>Acesso de Terceiros</strong>
                <p>Somente dados agregados e anonimizados são periodicamente transmitidos para serviços externos para ajudar o Prestador de Serviço a melhorar o Aplicativo e seu serviço. O Prestador de Serviço pode compartilhar suas informações com terceiros da maneira descrita nesta declaração de privacidade.</p>

                <p>Observe que o Aplicativo utiliza serviços de terceiros que possuem suas próprias Políticas de Privacidade sobre o manuseio de dados. Abaixo estão os links para a Política de Privacidade dos provedores de serviços de terceiros usados pelo Aplicativo:</p>
                <ul>
                    <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
                    <li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
                </ul>

                <p>O Prestador de Serviço pode divulgar Informações Fornecidas pelo Usuário e Informações Coletadas Automaticamente:</p>
                <ul>
                    <li>Conforme exigido por lei, como para cumprir uma intimação ou processo legal semelhante;</li>
                    <li>Quando acreditarem de boa fé que a divulgação é necessária para proteger seus direitos, proteger sua segurança ou a segurança de outros, investigar fraudes ou responder a uma solicitação governamental;</li>
                    <li>Com seus provedores de serviços de confiança que trabalham em seu nome, não têm uso independente das informações que divulgamos a eles e concordaram em aderir às regras estabelecidas nesta declaração de privacidade.</li>
                </ul>

                <br><strong>Direitos de Exclusão</strong>
                <p>Você pode interromper toda a coleta de informações pelo Aplicativo facilmente desinstalando-o. Você pode usar os processos padrão de desinstalação que podem estar disponíveis como parte do seu dispositivo móvel ou através do mercado de aplicativos móveis.</p>

                <br><strong>Política de Retenção de Dados</strong>
                <p>O Prestador de Serviço reterá os dados fornecidos pelo usuário enquanto você usar o Aplicativo e por um período razoável posteriormente. Se você deseja que excluam os dados fornecidos pelo usuário que você forneceu através do Aplicativo, entre em contato com eles em contato&#64;brazucaindica.pt e eles responderão em um tempo razoável.</p>

                <br><strong>Crianças</strong>
                <p>O Prestador de Serviço não usa o Aplicativo para solicitar dados conscientemente ou fazer marketing para crianças menores de 13 anos.</p>

                <p>O Aplicativo não se dirige a ninguém com menos de 13 anos. O Prestador de Serviço não coleta conscientemente informações pessoalmente identificáveis de crianças menores de 13 anos. No caso de descobrirem que uma criança menor de 13 anos forneceu informações pessoais, o Prestador de Serviço as excluirá imediatamente de seus servidores. Se você é um pai ou responsável e está ciente de que seu filho forneceu informações pessoais, entre em contato com o Prestador de Serviço (contato&#64;brazucaindica.pt) para que possam tomar as ações necessárias.</p>

                <br><strong>Segurança</strong>
                <p>O Prestador de Serviço está preocupado em proteger a confidencialidade de suas informações. O Prestador de Serviço fornece salvaguardas físicas, eletrônicas e processuais para proteger as informações que processam e mantêm.</p>

                <br><strong>Alterações</strong>
                <p>Esta Política de Privacidade pode ser atualizada periodicamente por qualquer motivo. O Prestador de Serviço notificará você sobre quaisquer alterações na Política de Privacidade atualizando esta página com a nova Política de Privacidade. Você é aconselhado a consultar esta Política de Privacidade regularmente para quaisquer alterações, pois o uso contínuo é considerado como aprovação de todas as alterações.</p>

                <p>Esta política de privacidade é válida a partir de 29 de maio de 2024.</p>

                <br><strong>Seu Consentimento</strong>
                <p>Ao usar o Aplicativo, você está consentindo com o processamento de suas informações conforme estabelecido nesta Política de Privacidade agora e conforme emendado por nós.</p>

                <br><strong>Contate-nos</strong>
                <p>Se você tiver quaisquer dúvidas sobre privacidade enquanto usa o Aplicativo, ou tiver perguntas sobre nossas práticas, por favor, entre em contato com o Prestador de Serviço via email em contato&#64;brazucaindica.pt.</p>
            </div>
        </mat-card-content>
    </mat-card>
</section>
