import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { CategoryService } from '../services/category.service';
import { S3Service } from '../services/s3.service';
import { Business, OperatingHour } from '../models/business.model';

declare var bootstrap: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  businesses: Business[] = [];
  newBusiness: Business;
  editBusiness: Business;
  categories: any[] = [];
  isEdit = false;
  username: string = 'admin';
  weekDays: string[] = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'];
  TipoDeNegocio: string[] = ['Presencial','Remoto','Hibrido'];
  OcultarWazeMaps: string[] = ['Sim','Nao'];

  filteredBusinesses: Business[] = []; // Lista filtrada para exibir
  searchTerm: string = ''; // Termo de pesquisa

  constructor(
    private apiService: ApiService,
    private categoryService: CategoryService,
    private s3Service: S3Service // Serviço S3 para upload de imagens
  ) {
    this.newBusiness = this.initializeNewBusiness();
    this.editBusiness = this.initializeNewBusiness();
  }

  ngOnInit() {
    this.loadBusinesses();
    this.loadCategories();
  }

  initializeNewBusiness(): Business {
    return {
      id: '',
      name: '',
      description: '',
      location: '',
      website: '',
      category: '',
      contact: '',
      address: '',
      instagram: '',
      facebook: '',
      iconName: '',
      operatingHours: this.initializeOperatingHours(),
      boltFood: '',
      glovo: '',
      uberEats: '',
      nomeBotaoWebsite: '',
      whatsapp: '',
      imageUrl: '',
      tipoDeNegocio: '',
      ocultarWazeMaps: '',
    };
  }

  initializeOperatingHours(): { [key: string]: OperatingHour[] } {
    const operatingHours: { [key: string]: OperatingHour[] } = {};
    this.weekDays.forEach(day => operatingHours[day] = [{ open: '', close: '' }, { open: '', close: '' }]);
    return operatingHours;
  }

  async loadBusinesses() {
    try {
      this.businesses = await this.apiService.getBusinesses();
    } catch (error) {
      console.error('Erro ao carregar negócios:', error);
    }
  }

  async loadCategories() {
    try {
      this.categories = await this.categoryService.getCategories();
      this.filteredBusinesses = [...this.businesses]; // Inicializa a lista filtrada
    } catch (error) {
      console.error('Erro ao carregar categorias:', error);
    }
  }

  filterBusinesses() {
    const term = this.searchTerm.toLowerCase();
    this.filteredBusinesses = this.businesses.filter(business =>
      business.name.toLowerCase().includes(term) ||
      business.category.toLowerCase().includes(term)
    );
  }

  async onFileSelected(event: any, mode: 'add' | 'edit', businessId: string) {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.type.split('/')[1]; // Exemplo: 'jpg'
      const fileName = `${businessId}.${fileExtension}`; // Exemplo: '12345.jpg'

      const imageUrl = await this.s3Service.uploadFileWithCustomName(file, fileName);

      if (mode === 'add') {
        this.newBusiness.imageUrl = imageUrl;
      } else if (mode === 'edit') {
        this.editBusiness.imageUrl = imageUrl;
      }

      // Salve o imageUrl no banco de dados associado ao businessId
    }
  }

  async addBusiness() {
    this.cleanEmptyHours(this.newBusiness.operatingHours);
    this.ensureOperatingHoursIsArray(this.newBusiness);
    if (this.newBusiness.name && this.newBusiness.category) {
      try {
        await this.apiService.addBusiness(this.newBusiness);
        this.newBusiness = this.initializeNewBusiness();
        this.loadBusinesses();
        this.closeModal('addBusinessModal');
      } catch (error) {
        console.error('Erro ao adicionar negócio:', error);
      }
    }
  }

  edit(business: Business) {
    this.isEdit = true;
    this.editBusiness = { ...business };
    if (!this.editBusiness.operatingHours) {
      this.editBusiness.operatingHours = this.initializeOperatingHours();
    } else {
      this.weekDays.forEach(day => {
        if (!this.editBusiness.operatingHours?.[day] || this.editBusiness.operatingHours[day].length === 0) {
          this.editBusiness.operatingHours[day] = [{ open: '', close: '' }, { open: '', close: '' }];
        }
      });
    }
    this.loadBusinessImage(this.editBusiness.id); // Carrega a imagem existente ao editar
    this.openModal('editBusinessModal');
  }

  async loadBusinessImage(businessId: string) {
    this.editBusiness.imageUrl = this.s3Service.getImageUrl(businessId);
  }

  async updateBusiness() {
    this.cleanEmptyHours(this.editBusiness.operatingHours);
    this.ensureOperatingHoursIsArray(this.editBusiness);
    if (this.editBusiness.name && this.editBusiness.category) {
      try {
        await this.apiService.updateBusiness(this.editBusiness.id, this.editBusiness);
        this.isEdit = false;
        this.editBusiness = this.initializeNewBusiness();
        this.loadBusinesses();
        this.closeModal('editBusinessModal');
      } catch (error) {
        console.error('Erro ao atualizar negócio:', error);
      }
    }
  }

  async deleteBusiness(id: string) {
    try {
      await this.apiService.deleteBusiness(id);
      this.loadBusinesses();
    } catch (error) {
      console.error('Erro ao deletar negócio:', error);
    }
  }

  resetForm() {
    this.isEdit = false;
    this.newBusiness = this.initializeNewBusiness();
    this.editBusiness = this.initializeNewBusiness();
  }

  openModal(modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
    if (modalId === 'addBusinessModal') {
      this.loadCategories();
    }
  }

  closeModal(modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modal = bootstrap.Modal.getInstance(modalElement);
      modal?.hide();
    }
  }

  cleanEmptyHours(operatingHours: { [key: string]: OperatingHour[] }) {
    this.weekDays.forEach(day => {
      operatingHours[day] = operatingHours[day].map(period => {
        return {
          open: period.open || '',
          close: period.close || ''
        };
      });
    });
  }

  ensureOperatingHoursIsArray(business: Business) {
    const operatingHours: { [key: string]: OperatingHour[] } = {};
    this.weekDays.forEach(day => {
      if (business.operatingHours && business.operatingHours[day]) {
        operatingHours[day] = business.operatingHours[day].map(period => {
          return {
            open: period.open !== null ? period.open : '',
            close: period.close !== null ? period.close : ''
          };
        });
      } else {
        operatingHours[day] = [{ open: '', close: '' }, { open: '', close: '' }];
      }
    });
    business.operatingHours = operatingHours;
  }
}
