import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  private s3: S3Client;
  private bucketName = 'fotoperfilbrazucaindica'; // Nome do seu bucket
  private region = 'eu-west-3';

  constructor() {
    this.s3 = new S3Client({
      region: this.region,
      credentials: {
        accessKeyId: 'AKIA6G75DVSYQYKOLLUT',
        secretAccessKey: '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'
      }
    });
  }
  async uploadFileWithCustomName(file: File, fileName: string): Promise<string> {
    const uploadParams = {
      Bucket: this.bucketName,
      Key: fileName,
      Body: file
    };
    const command = new PutObjectCommand(uploadParams);
    await this.s3.send(command);

    // Retorna a URL da imagem
    return `https://${this.bucketName}.s3.${this.region}.amazonaws.com/${fileName}`;
  }

  getImageUrl(businessId: string, extension: string = 'jpeg'): string {
    return `https://${this.bucketName}.s3.${this.region}.amazonaws.com/${businessId}.${extension}`;
  }
}