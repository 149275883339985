<div class="container">
    <div class="row">
      <div class="col-12 text-center mb-4">
        <h1>Categories</h1>
        <button class="btn btn-primary" (click)="openModal('addCategoryModal')">Adicionar Nova Categoria</button>
      </div>
    </div>
  
    <div class="row mt-5">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h2>Lista de Categorias</h2>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li *ngFor="let category of categories" class="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="mb-1">{{ category.name }}</h5>
                  <p class="mb-1">{{ category.iconName }}</p>
                </div>
                <div>
                  <button class="btn btn-info btn-sm me-2" (click)="edit(category); openModal('editCategoryModal')">Edit</button>
                  <button class="btn btn-danger btn-sm" (click)="deleteCategory(category.id)">Delete</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Add Category Modal -->
    <div class="modal fade" id="addCategoryModal" tabindex="-1" aria-labelledby="modal-basic-title" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Add New Category</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('addCategoryModal')"></button>
          </div>
          <div class="modal-body">
            <form (ngSubmit)="addCategory()">
              <div class="mb-3">
                <label for="categoryName" class="form-label">Name</label>
                <input id="categoryName" [(ngModel)]="newCategory.name" name="name" class="form-control" placeholder="Name" required />
              </div>
              <div class="mb-3">
                <label for="categoryIconName" class="form-label">Icon Name</label>
                <input id="categoryIconName" [(ngModel)]="newCategory.iconName" name="iconName" class="form-control" placeholder="Icon Name" required />
              </div>
              <button type="submit" class="btn btn-primary">Add</button>
              <button type="button" class="btn btn-secondary" (click)="closeModal('addCategoryModal')">Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Edit Category Modal -->
    <div class="modal fade" id="editCategoryModal" tabindex="-1" aria-labelledby="modal-basic-title" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Edit Category</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('editCategoryModal')"></button>
          </div>
          <div class="modal-body">
            <form (ngSubmit)="updateCategory()">
              <div class="mb-3">
                <label for="categoryName" class="form-label">Name</label>
                <input id="categoryName" [(ngModel)]="editCategory.name" name="name" class="form-control" placeholder="Name" required />
              </div>
              <div class="mb-3">
                <label for="categoryIconName" class="form-label">Icon Name</label>
                <input id="categoryIconName" [(ngModel)]="editCategory.iconName" name="iconName" class="form-control" placeholder="Icon Name" required />
              </div>
              <button type="submit" class="btn btn-primary">Update</button>
              <button type="button" class="btn btn-secondary" (click)="closeModal('editCategoryModal')">Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  