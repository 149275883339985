<div class="container login-container">
  <div class="row justify-content-center">
    
      <div class="card">
        <div class="card-header">
          <h2 class="card-title mb-0">Login</h2>
        </div>
        <div class="card-body">
          <form (ngSubmit)="onLogin()">
            <div class="form-group">
              <label for="username">Username:</label>
              <input type="text" id="username" [(ngModel)]="username" name="username" class="form-control" required>
            </div>
            <div class="form-group">
              <label for="password">Password:</label>
              <input type="password" id="password" [(ngModel)]="password" name="password" class="form-control" required>
            </div>
            <button type="submit" class="btn btn-primary btn-block">Login</button>
          </form>
        </div>
      </div>

  </div>
</div>