<div class="container">
  <div class="row">
    <div class="col-12 text-center mb-4">
      <h1>Dashboard</h1>
      <p>Bem Vindo ao Dashboard, {{ username }}!</p>
      <button class="btn btn-primary" (click)="openModal('addBusinessModal')">Adicionar novo Negocio</button>
      <button class="btn btn-secondary" [routerLink]="['/categories']">Manage Categories</button>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h2>Lista de Negocios</h2>
          <!-- Input para busca -->
          <!-- Campo de pesquisa para autocomplete -->
            <input type="text" class="form-control mt-2" placeholder="Busca Negocios.." [(ngModel)]="searchTerm"
              (input)="filterBusinesses()" />
        </div>
        <div class="card-body">
          <ul class="list-group">
            <li *ngFor="let business of filteredBusinesses; let i = index"
              class="list-group-item d-flex justify-content-between align-items-center">
              <div>
                <h5 class="mb-1">{{ i + 1 }} - {{ business.name }}</h5>
                <p class="mb-1">{{ business.category }}</p>
              </div>
              <div>
                <button class="btn btn-info btn-sm me-2" (click)="edit(business)">Edit</button>
                <button class="btn btn-danger btn-sm" (click)="deleteBusiness(business.id)">Delete</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Add Business Modal -->
  <div class="modal fade" id="addBusinessModal" tabindex="-1" aria-labelledby="modal-basic-title" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-basic-title">Add New Business</h5>
          <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('addBusinessModal')"></button>
        </div>
        <div class="modal-body">
          <form (ngSubmit)="addBusiness()">
            <!-- Existing fields -->

            <div class="mb-3 text-center">
              <label for="businessImage" class="form-label">Upload Image</label>
              <div *ngIf="newBusiness.imageUrl">
                <img [src]="newBusiness.imageUrl" alt="Business Image" class="img-thumbnail mb-2"
                  style="max-width: 150px;">
              </div>
              <input type="file" (change)="onFileSelected($event, 'add', newBusiness.id)" />
            </div>

            <div class="mb-3">
              <label for="businessName" class="form-label">ID:</label>
              <input id="businessName" [(ngModel)]="newBusiness.id" name="id" class="form-control" placeholder="id" />
            </div>

            <div class="mb-3">
              <label for="businessTipoDeNegocio" class="form-label">Tipo De Negocio</label>
              <select id="businessTipoDeNegocio" [(ngModel)]="newBusiness.tipoDeNegocio" name="tipoDeNegocio"
                class="form-select">
                <option *ngFor="let tipoDeNegocio of TipoDeNegocio" [value]="tipoDeNegocio">{{ tipoDeNegocio }}</option>
              </select>
            </div>

            <div class="mb-3">
              <label for="businessName" class="form-label">Name</label>
              <input id="businessName" [(ngModel)]="newBusiness.name" name="name" class="form-control"
                placeholder="Name" required />
            </div>
            <div class="mb-3">
              <label for="businessDescription" class="form-label">Description</label>
              <input id="businessDescription" [(ngModel)]="newBusiness.description" name="description"
                class="form-control" placeholder="Description" />
            </div>
            <div class="mb-3">
              <label for="businessLocation" class="form-label">Location</label>
              <input id="businessLocation" [(ngModel)]="newBusiness.location" name="location" class="form-control"
                placeholder="Location" />
            </div>
            <div class="mb-3">
              <label for="businessWebsite" class="form-label">Website</label>
              <input id="businessWebsite" [(ngModel)]="newBusiness.website" name="website" class="form-control"
                placeholder="Website" />
            </div>
            <div class="mb-3">
              <label for="businessCategory" class="form-label">Category</label>
              <select id="businessCategory" [(ngModel)]="newBusiness.category" name="category" class="form-select"
                required>
                <option *ngFor="let category of categories" [value]="category.name">{{ category.name }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="businessContact" class="form-label">Contact</label>
              <input id="businessContact" [(ngModel)]="newBusiness.contact" name="contact" class="form-control"
                placeholder="Contact" />
            </div>
            <div class="mb-3">
              <label for="businessAddress" class="form-label">Address</label>
              <input id="businessAddress" [(ngModel)]="newBusiness.address" name="address" class="form-control"
                placeholder="Address" />
            </div>

            <div class="mb-3">
              <label for="businessOcultarWazeMaps" class="form-label">Ocultar Waze e Google Maps</label>
              <select id="businessOcultarWazeMaps" [(ngModel)]="newBusiness.ocultarWazeMaps" name="ocultarWazeMaps"
                class="form-select">
                <option *ngFor="let ocultarWazeMaps of OcultarWazeMaps" [value]="ocultarWazeMaps">{{ ocultarWazeMaps }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label for="businessInstagram" class="form-label">Instagram</label>
              <input id="businessInstagram" [(ngModel)]="newBusiness.instagram" name="instagram" class="form-control"
                placeholder="Instagram" />
            </div>
            <div class="mb-3">
              <label for="businessFacebook" class="form-label">Facebook</label>
              <input id="businessFacebook" [(ngModel)]="newBusiness.facebook" name="facebook" class="form-control"
                placeholder="Facebook" />
            </div>
            <div class="mb-3">
              <label for="businessIconName" class="form-label">Icon Name</label>
              <input id="businessIconName" [(ngModel)]="newBusiness.iconName" name="iconName" class="form-control"
                placeholder="Icon Name" />
            </div>
            <!-- New fields -->
            <div class="mb-3">
              <label for="businessBoltFood" class="form-label">Bolt Food</label>
              <input id="businessBoltFood" [(ngModel)]="newBusiness.boltFood" name="boltFood" class="form-control"
                placeholder="Bolt Food URL" />
            </div>
            <div class="mb-3">
              <label for="businessGlovo" class="form-label">Glovo</label>
              <input id="businessGlovo" [(ngModel)]="newBusiness.glovo" name="glovo" class="form-control"
                placeholder="Glovo URL" />
            </div>
            <div class="mb-3">
              <label for="businessUberEats" class="form-label">Uber Eats</label>
              <input id="businessUberEats" [(ngModel)]="newBusiness.uberEats" name="uberEats" class="form-control"
                placeholder="Uber Eats URL" />
            </div>
            <div class="mb-3">
              <label for="businessNomeBotaoWebsite" class="form-label">Button Website Name</label>
              <input id="businessNomeBotaoWebsite" [(ngModel)]="newBusiness.nomeBotaoWebsite" name="nomeBotaoWebsite"
                class="form-control" placeholder="Button Website Name" />
            </div>
            <div class="mb-3">
              <label for="businessWhatsapp" class="form-label">WhatsApp</label>
              <input id="businessWhatsapp" [(ngModel)]="newBusiness.whatsapp" name="whatsapp" class="form-control"
                placeholder="WhatsApp URL" />
            </div>
            <!-- Operating Hours Fields -->
            <div *ngFor="let day of weekDays">
              <div class="mb-3">
                <label for="hours-{{day}}" class="form-label">{{day}}</label>
                <div class="input-group">
                  <input id="hours-{{day}}-open1" [(ngModel)]="newBusiness.operatingHours[day][0].open"
                    name="newHours-{{day}}-open1" class="form-control" placeholder="Open 1" />
                  <input id="hours-{{day}}-close1" [(ngModel)]="newBusiness.operatingHours[day][0].close"
                    name="newHours-{{day}}-close1" class="form-control" placeholder="Close 1" />
                </div>
                <div class="input-group mt-2" *ngIf="newBusiness.operatingHours[day].length > 1">
                  <input id="hours-{{day}}-open2" [(ngModel)]="newBusiness.operatingHours[day][1].open"
                    name="newHours-{{day}}-open2" class="form-control" placeholder="Open 2" />
                  <input id="hours-{{day}}-close2" [(ngModel)]="newBusiness.operatingHours[day][1].close"
                    name="newHours-{{day}}-close2" class="form-control" placeholder="Close 2" />
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Add</button>
            <button type="button" class="btn btn-secondary" (click)="closeModal('addBusinessModal')">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit Business Modal -->
  <div class="modal fade" id="editBusinessModal" tabindex="-1" aria-labelledby="modal-basic-title" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-basic-title">Edit Business</h5>
          <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('editBusinessModal')"></button>
        </div>
        <div class="modal-body">
          <form (ngSubmit)="updateBusiness()">
            <!-- Existing fields -->

            <div class="mb-3 text-center">
              <label for="editBusinessImage" class="form-label">Upload Image</label>
              <div *ngIf="editBusiness.imageUrl">
                <img [src]="editBusiness.imageUrl" alt="Business Image" class="img-thumbnail mb-2"
                  style="max-width: 150px;">
              </div>
              <!-- Suponha que você esteja dentro de um loop de negócios e tenha acesso ao ID do negócio -->
              <input type="file" (change)="onFileSelected($event, 'edit', editBusiness.id)" />
            </div>

            <div class="mb-3">
              <label for="businessName" class="form-label">ID:</label>
              <input id="businessName" [(ngModel)]="editBusiness.id" name="id" class="form-control" placeholder="id" />
            </div>
            <div class="mb-3">
              <label for="businessTipoDeNegocio" class="form-label">Tipo De Negocio</label>
              <select id="businessTipoDeNegocio" [(ngModel)]="editBusiness.tipoDeNegocio" name="tipoDeNegocio"
                class="form-select">
                <option *ngFor="let tipoDeNegocio of TipoDeNegocio" [value]="tipoDeNegocio">{{ tipoDeNegocio }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="businessName" class="form-label">Name</label>
              <input id="businessName" [(ngModel)]="editBusiness.name" name="name" class="form-control"
                placeholder="Name" required />
            </div>
            <div class="mb-3">
              <label for="businessDescription" class="form-label">Description</label>
              <input id="businessDescription" [(ngModel)]="editBusiness.description" name="description"
                class="form-control" placeholder="Description" />
            </div>
            <div class="mb-3">
              <label for="businessLocation" class="form-label">Location</label>
              <input id="businessLocation" [(ngModel)]="editBusiness.location" name="location" class="form-control"
                placeholder="Location" />
            </div>
            <div class="mb-3">
              <label for="businessWebsite" class="form-label">Website</label>
              <input id="businessWebsite" [(ngModel)]="editBusiness.website" name="website" class="form-control"
                placeholder="Website" />
            </div>
            <div class="mb-3">
              <label for="businessCategory" class="form-label">Category</label>
              <select id="businessCategory" [(ngModel)]="editBusiness.category" name="category" class="form-select"
                required>
                <option *ngFor="let category of categories" [value]="category.name">{{ category.name }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="businessContact" class="form-label">Contact</label>
              <input id="businessContact" [(ngModel)]="editBusiness.contact" name="contact" class="form-control"
                placeholder="Contact" />
            </div>
            <div class="mb-3">
              <label for="businessAddress" class="form-label">Address</label>
              <input id="businessAddress" [(ngModel)]="editBusiness.address" name="address" class="form-control"
                placeholder="Address" />
            </div>
            <div class="mb-3">
              <label for="businessOcultarWazeMaps" class="form-label">Ocultar Waze e Google Maps</label>
              <select id="businessOcultarWazeMaps" [(ngModel)]="editBusiness.ocultarWazeMaps" name="ocultarWazeMaps"
                class="form-select">
                <option *ngFor="let ocultarWazeMaps of OcultarWazeMaps" [value]="ocultarWazeMaps">{{ ocultarWazeMaps }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="businessInstagram" class="form-label">Instagram</label>
              <input id="businessInstagram" [(ngModel)]="editBusiness.instagram" name="instagram" class="form-control"
                placeholder="Instagram" />
            </div>
            <div class="mb-3">
              <label for="businessFacebook" class="form-label">Facebook</label>
              <input id="businessFacebook" [(ngModel)]="editBusiness.facebook" name="facebook" class="form-control"
                placeholder="Facebook" />
            </div>
            <div class="mb-3">
              <label for="businessIconName" class="form-label">Icon Name</label>
              <input id="businessIconName" [(ngModel)]="editBusiness.iconName" name="iconName" class="form-control"
                placeholder="Icon Name" />
            </div>
            <!-- New fields -->
            <div class="mb-3">
              <label for="businessBoltFood" class="form-label">Bolt Food</label>
              <input id="businessBoltFood" [(ngModel)]="editBusiness.boltFood" name="boltFood" class="form-control"
                placeholder="Bolt Food URL" />
            </div>
            <div class="mb-3">
              <label for="businessGlovo" class="form-label">Glovo</label>
              <input id="businessGlovo" [(ngModel)]="editBusiness.glovo" name="glovo" class="form-control"
                placeholder="Glovo URL" />
            </div>
            <div class="mb-3">
              <label for="businessUberEats" class="form-label">Uber Eats</label>
              <input id="businessUberEats" [(ngModel)]="editBusiness.uberEats" name="uberEats" class="form-control"
                placeholder="Uber Eats URL" />
            </div>
            <div class="mb-3">
              <label for="businessNomeBotaoWebsite" class="form-label">Button Website Name</label>
              <input id="businessNomeBotaoWebsite" [(ngModel)]="editBusiness.nomeBotaoWebsite" name="nomeBotaoWebsite"
                class="form-control" placeholder="Button Website Name" />
            </div>
            <div class="mb-3">
              <label for="businessWhatsapp" class="form-label">WhatsApp</label>
              <input id="businessWhatsapp" [(ngModel)]="editBusiness.whatsapp" name="whatsapp" class="form-control"
                placeholder="WhatsApp URL" />
            </div>
            <!-- Operating Hours Fields -->
            <div *ngFor="let day of weekDays">
              <div class="mb-3">
                <label for="hours-{{day}}" class="form-label">{{day}}</label>
                <div class="input-group">
                  <input id="hours-{{day}}-open1" [(ngModel)]="editBusiness.operatingHours[day][0].open"
                    name="editHours-{{day}}-open1" class="form-control" placeholder="Open 1" />
                  <input id="hours-{{day}}-close1" [(ngModel)]="editBusiness.operatingHours[day][0].close"
                    name="editHours-{{day}}-close1" class="form-control" placeholder="Close 1" />
                </div>
                <div class="input-group mt-2" *ngIf="editBusiness.operatingHours[day].length > 1">
                  <input id="hours-{{day}}-open2" [(ngModel)]="editBusiness.operatingHours[day][1].open"
                    name="editHours-{{day}}-open2" class="form-control" placeholder="Open 2" />
                  <input id="hours-{{day}}-close2" [(ngModel)]="editBusiness.operatingHours[day][1].close"
                    name="editHours-{{day}}-close2" class="form-control" placeholder="Close 2" />
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-primary">Update</button>
            <button type="button" class="btn btn-secondary" (click)="closeModal('editBusinessModal')">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>