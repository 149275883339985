import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../services/category.service';

declare var bootstrap: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categories: any[] = [];
  newCategory = this.getEmptyCategory();
  editCategory = this.getEmptyCategory();
  isEdit = false;

  constructor(private categoryService: CategoryService) {}

  ngOnInit() {
    this.loadCategories();
  }

  getEmptyCategory() {
    return {
      id: '',
      name: '',
      iconName: ''
    };
  }

  async loadCategories() {
    try {
      this.categories = await this.categoryService.getCategories();
    } catch (error) {
      console.error('Erro ao carregar categorias:', error);
    }
  }

  async addCategory() {
    if (this.newCategory.name && this.newCategory.iconName) {
      try {
        await this.categoryService.addCategory(this.newCategory);
        this.newCategory = this.getEmptyCategory();
        this.loadCategories();
        this.closeModal('addCategoryModal'); // Fechar o modal após adicionar
      } catch (error) {
        console.error('Erro ao adicionar categoria:', error);
      }
    }
  }

  edit(category: any) {
    this.isEdit = true;
    this.editCategory = { ...category };
  }

  async updateCategory() {
    if (this.editCategory.name && this.editCategory.iconName) {
      try {
        await this.categoryService.updateCategory(this.editCategory.id, this.editCategory);
        this.isEdit = false;
        this.editCategory = this.getEmptyCategory();
        this.loadCategories();
        this.closeModal('editCategoryModal'); // Fechar o modal após atualizar
      } catch (error) {
        console.error('Erro ao atualizar categoria:', error);
      }
    }
  }

  async deleteCategory(id: string) {
    try {
      await this.categoryService.deleteCategory(id);
      this.loadCategories();
    } catch (error) {
      console.error('Erro ao deletar categoria:', error);
    }
  }

  resetForm() {
    this.isEdit = false;
    this.newCategory = this.getEmptyCategory();
    this.editCategory = this.getEmptyCategory();
  }

  openModal(modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
  }

  closeModal(modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modal = bootstrap.Modal.getInstance(modalElement);
      modal.hide();
    }
  }
}
