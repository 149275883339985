import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private API_URL = 'https://brazuca-indica-api-daabaf6ea0bb.herokuapp.com/categories';

  constructor() { }

  async getCategories() {
    try {
      const response = await axios.get(this.API_URL);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
      throw error;
    }
  }

  async addCategory(category: any) {
    try {
      const response = await axios.post(this.API_URL, category);
      return response.data;
    } catch (error) {
      console.error('Erro ao adicionar categoria:', error);
      throw error;
    }
  }

  async updateCategory(id: string, category: any) {
    try {
      const response = await axios.put(`${this.API_URL}/${id}`, category);
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar categoria:', error);
      throw error;
    }
  }

  async deleteCategory(id: string) {
    try {
      const response = await axios.delete(`${this.API_URL}/${id}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar categoria:', error);
      throw error;
    }
  }
}
