import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private API_URL = 'https://brazuca-indica-api-daabaf6ea0bb.herokuapp.com/businesses';

  constructor() { }

  async getBusinesses() {
    try {
      const response = await axios.get(this.API_URL);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar negócios:', error);
      throw error;
    }
  }

  async addBusiness(business: any) {
    try {
      const response = await axios.post(this.API_URL, business);
      return response.data;
    } catch (error) {
      console.error('Erro ao adicionar negócio:', error);
      throw error;
    }
  }

  async updateBusiness(id: string, business: any) {
    try {
      const response = await axios.put(`${this.API_URL}/${id}`, business);
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar negócio:', error);
      throw error;
    }
  }

  async deleteBusiness(id: string) {
    try {
      const response = await axios.delete(`${this.API_URL}/${id}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar negócio:', error);
      throw error;
    }
  }
}
